import CableReady from 'cable_ready';
import consumer from 'channels/consumer';

const UserChannel = consumer.subscriptions.create('UserChannel', {
  connected() {},
  disconnected() {
    this.unsubscribe();
  },
  received(data) {
    if (data.cableReady) {
      setTimeout(() => {
        CableReady.perform(data.operations);
      }, 200);
    }

    if (data.check_sessison_expired) {
      $.ajax({
        url: '/users/check_session_expired',
        type: 'GET',
        error: (xhr) => {
          if (xhr.status == 401) {
            window.location.href = '/?session_expired=true';
          }
        },
      });
    }
  },
});

export default UserChannel;
