import ApplicationController from './application_controller'
import { checkDeviceSupport } from "src/components"

export default class extends ApplicationController {
  connect() {
    super.connect()

    this.checkDeviceSupported()
  }

  checkDeviceSupported() {
    let isSessionLivePage = !!location.pathname.match(/\/events\/sessions\/[A-Za-z0-9-_]+\/live/)

    if (isSessionLivePage) {
      checkDeviceSupport()
    }
  }
}
