import ApplicationController from "../application_controller"
import CableReady from "cable_ready"
import consumer from "channels/consumer"

export default class extends ApplicationController {
  static targets = [
    "questionList",
    "activeQuestions",
    "hiddenQuestions",
    "activeButton",
    "hiddenButton",
    "questionResponseErrorMessages",
    "createQuestionResponseModal",
  ]

  connect() {
    super.connect()
    this.connectQuestionChannel()
    this.activeQuestionList = this.questionListTarget.querySelector("div[active_question]")
    this.hiddenQuestionList = this.questionListTarget.querySelector("div[hidden_question]")
  }

  disconnect() {
    this.channel && this.channel.unsubscribe()
  }

  connectQuestionChannel() {
    this.channel = consumer.subscriptions.create("QuestionChannel", {
      connected: () => {
        let { resourceId, resourceType } = this.element.dataset

        if (resourceType === "Livestream") {
          this.channel.perform("follow_livestream", { id: resourceId, broadcast_type: "operator" })
        } else {
          this.channel.perform("follow", { id: resourceId, type: resourceType })
        }
      },
      received: this.handleQuestionChannelReceive.bind(this),
    })

    this.StimulusReflex = { channel: this.channel.identifier, subscription: this.channel }
  }

  onFilterQuestions(e) {
    let isPublic = e.currentTarget.dataset.isPublic

    isPublic = isPublic === "true"
    if (isPublic) {
      this.activeButtonTarget.classList.remove("btn-secondary")
      this.hiddenButtonTarget.classList.remove("btn-primary")
      this.activeButtonTarget.classList.add("btn-primary")

      this.activeQuestionList.classList.remove("d-none")
      this.hiddenQuestionList.classList.add("d-none")
    } else {
      this.hiddenButtonTarget.classList.remove("btn-secondary")
      this.activeButtonTarget.classList.remove("btn-primary")
      this.hiddenButtonTarget.classList.add("btn-primary")

      this.activeQuestionList.classList.add("d-none")
      this.hiddenQuestionList.classList.remove("d-none")
    }
  }

  togglePublic(e) {
    let questionId = e.currentTarget.dataset.questionId
    this.stimulate("QuestionsReflex#toggle_public", questionId)
  }

  onClickCreateResponse(e) {
    let questionId = e.currentTarget.dataset.questionId

    this.request("/questions/" + questionId + "/form?operator=true", "GET").then((result) => {
      this.createQuestionResponseModalTarget.getElementsByClassName("question-response-form")[0].innerHTML = result.data.data
      this.questionResponseModal = this.modalController().modal(this.createQuestionResponseModalTarget)
      this.questionResponseModal.show()
    })
  }

  saveQuestionResponse(e) {
    let that = this
    let questionId = document.getElementById("question_id").value
    let questionResponse = document.getElementsByName("question[response]")[0].value

    let data = {
      question: {
        response: questionResponse,
      },
    }

    this.request("/questions/" + questionId, "PATCH", data).then((result) => {
      that.questionResponseModal.hide()
    })
  }

  handleQuestionChannelReceive(data) {
    if (data.cableReady) {
      CableReady.perform(data.operations)
    } else {
      let question = JSON.parse(data.question).data.attributes
      let { html_locales: htmlLocales, event_type: eventType } = data
      let htmlLocale = typeof htmlLocales === "object" ? htmlLocales[I18n.locale] : htmlLocales

      switch (eventType) {
        case "question-add":
          this.appendQuestionToList(question, htmlLocale)
          break
        case "question-update":
          this.updateQuestionInList(question, htmlLocale)
        default:
          break
      }
    }
  }

  appendQuestionToList(question, html) {
    if (question.public) {
      this.activeQuestionList.insertAdjacentHTML("afterbegin", html)
    } else {
      this.hiddenQuestionList.insertAdjacentHTML("afterbegin", html)
    }
  }

  updateQuestionInList(question, html) {
    if (question.public) {
      this.activeQuestionList.querySelector(`.active-question-item-${question.id}`).outerHTML = html
    } else {
      this.hiddenQuestionList.querySelector(`.hidden-question-item-${question.id}`).outerHTML = html
    }
  }
}
