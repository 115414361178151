import ApplicationController from './application_controller'
import CableReady from 'cable_ready'
import consumer from 'channels/consumer'

export default class extends ApplicationController {
  static targets = ['venue', 'timer', 'timerText']
  connect() {
    super.connect()
  }

  initialize() {
    const subscriptionWhiteListActions = ['timer_show']
    const pageAction = this.element.dataset.action
    if (subscriptionWhiteListActions.includes(pageAction)) {
      const venueId = this.venueTarget.dataset.venueId
      this.connectVenueChannel(venueId)
    }
  }

  connectVenueChannel(id) {
    this.channel = consumer.subscriptions.create(
      {
        channel: 'VenueChannel',
        resource_id: id,
        resource_type: 'Talk'
      },
      {
        connected: () => {},
        received: (data) => {
          if (data.cableReady) {
            CableReady.perform(data.operations)
          }
        },
      }
    )
  }

  updated(res) {
    let { detail } = res
    this.timerTarget.dataset.duration = detail.talk.duration
  }
}
