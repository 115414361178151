import { Controller } from "stimulus";
import Modal from "bootstrap/js/dist/modal";

export default class extends Controller {
  static targets = ["modal", "title", "body"];

  modal(element, config = {}) {
    return new Modal(element, config);
  }

  openModal(element, options = {}) {
    this.modal(element).show(options);
  }

  closeModal(element) {
    this.modal(element).hide();
  }
}
