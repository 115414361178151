import { Controller } from "stimulus";
import videojs from "video.js";

export default class extends Controller {
  static targets = ["player"];

  initialize() {
    this._initPlayer();
  }

  _initPlayer() {
    this.player = videojs(this.playerTarget, {
      autoplay: false,
      controls: true,
      preload: "auto",
      playsinline: true,
    });
  }
}
