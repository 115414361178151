import ApplicationController from "../application_controller"
import CableReady from "cable_ready"
import consumer from "channels/consumer"
import notify from "components/notify"

export default class extends ApplicationController {
  static targets = ["stateNoteSection", "modalSpeaker", "operatorButton"]

  connect() {
    super.connect()

    let { resourceId, resourceType, broadcastScope } = this.element.dataset

    this.channel = consumer.subscriptions.create(
      {
        channel: "AgendaChannel",
        resource_id: resourceId,
        resource_type: resourceType,
        scope: broadcastScope,
      },
      {
        connected: () => {
          let sectionAgendaContent = this.element.querySelector(".section-agenda-content")

          if (sectionAgendaContent) {
            sectionAgendaContent.classList.remove("loading")
          }
        },
        disconnected: () => {},
        received: (data) => {
          if (data.cableReady) {
            CableReady.perform(data.operations)
            return
          }
        },
      }
    )

    this.StimulusReflex = { channel: this.channel.identifier, subscription: this.channel }
  }

  disconnect() {
    this.channel && this.channel.unsubscribe()
  }

  onStart(event) {
    event.preventDefault()

    this.stimulate("Operator::AgendaReflex#start", this.element)
  }

  onComplete(event) {
    event.preventDefault()

    const { redirectUrl } = event.currentTarget.dataset

    this.stimulate("Operator::AgendaReflex#complete", this.element)
    window.location.replace(redirectUrl)
  }

  makeComplete(event) {
    event.preventDefault()

    var confirmMsg = ""
    var element = event.currentTarget
    element.blur()

    confirmMsg =
      element.dataset.completed === "true" || element.dataset.completed === true
        ? I18n.t("operator.confirm_messages.session_incompleted")
        : I18n.t("operator.confirm_messages.session_completed")

    if (confirm(confirmMsg)) {
      this.request(element.dataset.url, "PUT")
        .then((response) => {
          var msg = response.data.completed
            ? I18n.t("operator.flash.session_completed")
            : I18n.t("operator.flash.session_not_completed")
          element.dataset.completed = response.data.completed

          if (response.data.completed) {
            element.classList.add("btn-success")
            element.classList.remove("btn-info")
          } else {
            element.classList.add("btn-info")
            element.classList.remove("btn-success")
          }

          notify({ title: I18n.t("notify_status.successful"), message: msg }, { type: "success" })
        })
        .catch((error) => {
          var res = error.response
          notify(
            {
              title: `${error.name}: ${res.statusText}`,
              message: res.data.error,
            },
            {
              type: "danger",
            }
          )
        })
    }
  }

  openModalSpeaker(event) {
    var speakerController = this.application.getControllerForElementAndIdentifier(
      this.modalSpeakerTarget,
      "operator--speakers"
    )
    speakerController.showModal(event)
  }

  openNoteModal(event) {
    var noteController = this.application.getControllerForElementAndIdentifier(
      this.stateNoteSectionTarget,
      "operator--state-notes"
    )
    noteController.openNoteModal(event)
  }

  updateTalkNow(event) {
    event.preventDefault()
    event.currentTarget.blur()

    let { resourceType, resourceId } = this.element.dataset
    this.stimulate("Operator::TalkOnNowReflex#update", event.currentTarget, resourceType, resourceId)
  }
}
