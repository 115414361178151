import { Controller } from "stimulus";
export default class extends Controller {
  onMarkAllAsRead(event) {
    event.preventDefault();

    const notificationCards = this.element.querySelectorAll(
      ".system-notification-card.unread"
    );

    if (notificationCards.length && App.notifications) {
      App.notifications.perform("mark_as_read");

      notificationCards.forEach((notificationCard) => {
        let status = notificationCard.querySelector(
          ".system-notification-status"
        );

        status.innerText = I18n.t("system_notifications.unread");
        notificationCard.classList.remove("unread");
      });
    }
  }

  changeStatus(event) {
    event.preventDefault();

    const target = event.target;
    const notificationCard = target.closest(".system-notification-card");

    if (notificationCard && App.notifications) {
      let { id, kind } = notificationCard.dataset;

      if (notificationCard.classList.contains("unread")) {
        target.innerText = I18n.t("system_notifications.unread");
        App.notifications.perform("mark_as_read", { id, kind });
      } else {
        target.innerText = I18n.t("system_notifications.read");
        App.notifications.perform("mark_as_unread", { id, kind });
      }

      notificationCard.classList.toggle("unread");
    }
  }
}
