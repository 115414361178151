// Append a param to all images to fix Chrome browser cache issue
$(function() {
  $('img').map(function() {
    const imgSrc = this.src

    if (imgSrc.indexOf("http") != -1) {
      if (imgSrc.indexOf("?") != -1) {
        this.src = this.src + "&v1"
      } else {
        this.src = this.src + "?v1"
      }
    }
  })
});
