import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["modal", "modalBody"]

  connect() {
    super.connect()
  }

  openForm(event) {
    event.preventDefault()
    var url = event.target.href
    var csrfToken = document.querySelector('head [name="csrf-token"]').content
    var self = this

    $.ajax({
      type: "GET",
      url: url,
      success: (res) => {
        $(self.modalBodyTarget).html(res)
        $(self.modalTarget).modal("show")
        // Avoid to Invalid CSRF-TOKEN
        var $authTokenFields = $(
          '#resourceModal form [name="authenticity_token"]:not([value])'
        )
        $authTokenFields.val(csrfToken)
      },
      error: function (request, status, error) {
        console.log("ajax call went wrong:" + request.responseText)
      },
    })
  }
}
