import ApplicationController from "../application_controller"
import notify from "components/notify"

export default class extends ApplicationController {
  static targets = ["stateNoteSection", "chatList"]

  makeComplete(event) {
    event.preventDefault()

    var confirmMsg = ""
    var element = event.currentTarget
    element.blur()

    confirmMsg =
      element.dataset.completed === "true" || element.dataset.completed === true
        ? I18n.t("operator.confirm_messages.session_incompleted")
        : I18n.t("operator.confirm_messages.session_completed")

    if (confirm(confirmMsg)) {
      this.request(element.dataset.url, "PUT")
        .then((response) => {
          var msg = response.data.completed
            ? I18n.t("operator.flash.session_completed")
            : I18n.t("operator.flash.session_not_completed")
          element.dataset.completed = response.data.completed

          if (response.data.completed) {
            element.classList.add("btn-success")
            element.classList.remove("btn-info")
          } else {
            element.classList.add("btn-info")
            element.classList.remove("btn-success")
          }

          notify({ title: I18n.t("notify_status.successful"), message: msg }, { type: "success" })
        })
        .catch((error) => {
          var res = error.response
          notify(
            {
              title: `${error.name}: ${res.statusText}`,
              message: res.data.error,
            },
            {
              type: "danger",
            }
          )
        })
    }
  }

  openModalSpeaker(event) {
    var speakerController = this.application.getControllerForElementAndIdentifier(
      this.element.querySelector("[data-operator--sections-target='modalSpeaker']"),
      "operator--speakers"
    )
    speakerController.showModal(event)
  }

  openNoteModal(event) {
    var noteController = this.application.getControllerForElementAndIdentifier(
      this.stateNoteSectionTarget,
      "operator--state-notes"
    )
    noteController.openNoteModal(event)
  }
}
