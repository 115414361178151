$(document).ready(function () {
  const canvas = document.getElementById('pdf-presenter')
  if (!canvas) return;

  const RESOLUTION_VIEWPORT_WIDTH = 2
  const parentScreenWidth = $('#pdf-presenter').parent().width()
  const pdfjsLib = window['pdfjs-dist/build/pdf'];
  let pdfDoc = null,
      pageNum = 1,
      pageRendering = false,
      pageNumPending = null,
      url = canvas.attributes['data-src'].value,
      ctx = canvas.getContext('2d'),
      scalePercent = 1.0;

  pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';
  const queueRenderPage = (num) => pageRendering ? pageNumPending = num : renderPage(num)
  const onPrevPage = () => {
    if (pageNum <= 1) { return; }
    pageNum--;
    queueRenderPage(pageNum);
  }
  document.getElementById('prev-presenter').addEventListener('click', onPrevPage);

  const onNextPage = () => {
    if (pageNum >= pdfDoc.numPages) { return; }
    pageNum++;
    queueRenderPage(pageNum);
  }
  document.getElementById('next-presenter').addEventListener('click', onNextPage);

  const renderPage = (num) => {
    pageRendering = true;
    // Using promise to fetch the page
    pdfDoc.getPage(num).then(function(page) {
      const tmpViewport = page.getViewport({ scale: 1 });
      const calculatorScale = parentScreenWidth / Number(tmpViewport.viewBox[RESOLUTION_VIEWPORT_WIDTH])
      const scale = calculatorScale * scalePercent
      const viewport = page.getViewport({scale: scale});
      canvas.height = viewport.height
      canvas.width = viewport.width
      // Render PDF page into canvas context
      const renderTask = page.render({
        canvasContext: ctx,
        viewport: viewport,
        transform: [1, 0, 0, 1, 0, 0]
      });
      // Wait for rendering to finish
      renderTask.promise.then(function() {
        pageRendering = false;
        if (pageNumPending !== null) {
          // New page rendering is pending
          renderPage(pageNumPending);
          pageNumPending = null;
        }
      });
    });

    // Update page counters
    document.getElementById('page_num_presenter').textContent = num;
  }

  const zoomIn = () => {
    if (scalePercent < 2.0) {
      scalePercent += 0.1;
      updateZoom();
    }
  }

  const zoomOut = () => {
    if (scalePercent > 0.5) {
      scalePercent -= 0.1;
      updateZoom();
    }
  }

  const zoomReset = () => {
    scalePercent = 1.0;
    updateZoom();
  }

  document.querySelector("#pdf-zoom-in").onclick = zoomIn;
  document.querySelector("#pdf-zoom-out").onclick = zoomOut;
  document.querySelector("#pdf-zoom-reset").onclick = zoomReset;

  const updateZoom = () => {
    document.querySelector("#zoom-percent").innerHTML = Math.round(
      scalePercent * 100
    );
    renderPage(pageNum);
  }

  pdfjsLib.getDocument(url).promise.then(function(pdfDoc_) {
    pdfDoc = pdfDoc_;
    const totalPage = pdfDoc.numPages;
    document.getElementById('page_count_presenter').textContent = totalPage
    if (totalPage > 1) {
       $('#prev-presenter').removeClass('d-none')
       $('#next-presenter').removeClass('d-none')
    }

    renderPage(pageNum);
  });
})
