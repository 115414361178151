import ApplicationController from '../application_controller'
import notify from 'components/notify'

export default class extends ApplicationController {
  static targets = ['modalWebcast', 'webcastForm']

  initialize() {
    this._createWebcastURL
    this._profileable
  }

  connect() {
    Promise.resolve().then(() => {
      this.modalWebcast = this.modalController().modal(this.modalWebcastTarget, {
        backdrop: 'static',
        keyboard: false,
      })
    })
  }

  setCreateWebcastURL(url) {
    this._createWebcastURL = url
  }

  getCreateWebcastURL() {
    return this._createWebcastURL
  }

  openWebcastModal(event) {
    event.preventDefault()

    var target = event.currentTarget
    var { url, id, className, webcastUrl, webcastPoster, publishingApproval, publishingBlocked } = { ...target.dataset }
    var inputName = `${className}_id`
    var $webcastForm = $(this.webcastFormTarget)

    var { s3DirectFields } = this.webcastFormTarget.dataset
    if (!!s3DirectFields) {
      s3DirectFields = JSON.parse(s3DirectFields)
    }

    target.blur()
    this.setCreateWebcastURL(url)
    this.request('/s3_direct_post/request_policy', 'GET', {
      model_name: className,
      object_id: id,
      field_names: s3DirectFields,
    })
      .then((response) => {
        const data = response.data
        for (const key in data) {
          const fieldData = data[key] || {}
          const $field = $webcastForm.find(`input[name="${className}[upload_direct]"][data-field-name="${key}"]`)

          $field.data('form', fieldData['form-data'])
        }

        this.modalWebcast.show()
      })
      .catch((error) => {
        notify(error.message, {
          type: 'danger',
        })
      })

    $webcastForm.append(
      $('<input>', {
        type: 'hidden',
        id: `${inputName}_${new Date().getTime()}`,
        name: inputName,
        value: id,
      })
    )
    $webcastForm.find(`input[name="${className}[webcast_url]"]`).val(webcastUrl)
    $webcastForm.find(`input[name="${className}[webcast_poster]"]`).val(webcastPoster)
    $webcastForm.find(`[name$="[publishing_approval]"][value="${publishingApproval}"]`).prop('checked', publishingApproval)
    $webcastForm.find(`[name$="[publishing_blocked]"][value="${publishingBlocked}"]`).prop('checked', publishingBlocked)
  }
}
