import { EmojiButton } from "@joeattardi/emoji-button";

$(function() {
  const livestreamChatEmojiButton = document.querySelector('#emoji-button');
  if(livestreamChatEmojiButton) {
    const picker = new EmojiButton({
      emojiSize: '1.2em'
    });

    picker.on('emoji', selection => {
      document.querySelector('.livestream-chat-form-textarea').value += selection.emoji;
    });

    livestreamChatEmojiButton.addEventListener('click', () => {
      picker.togglePicker(livestreamChatEmojiButton);
    });
  }
});
