import ApplicationController from '../application_controller'
import consumer from 'channels/consumer'
import CableReady from 'cable_ready'
import CountdownTimer from 'components/countdown_timer'

export default class extends ApplicationController {
  static targets = ['timer']

  initialize() {
    this._initTalkTimerChannel()
  }

  connect() {
    super.connect()

    this._replaceStimulusReflexChannel()
    this._initCountdownTimer()
  }

  disconnect() {
    this.channel && this.channel.unsubscribe()
  }

  _initTalkTimerChannel() {
    const { resourceId, resourceType } = this.element.dataset
    const actionCableParams = {
      channel: 'TalkTimerChannel',
      resource_id: resourceId,
      resource_type: resourceType,
    }

    this.channel =
      consumer.subscriptions.findAll(JSON.stringify(actionCableParams))[0] ||
      consumer.subscriptions.create(actionCableParams, {
        connected: () => {
          setTimeout(
            () => {
              this.timerReflex(null)
            },
            500,
            this
          )
          this.element.classList.remove('loading')
        },
        disconnected: () => {},
        received: (data) => {
          if (data.cableReady) {
            CableReady.perform(data.operations)
            return
          }
        },
      })
  }

  _replaceStimulusReflexChannel() {
    this.StimulusReflex = { channel: this.channel.identifier, subscription: this.channel }
  }

  _initCountdownTimer() {
    let { duration, startedAt, stoppedAt, isStarted } = this.element.dataset

    this.countdownTimer = new CountdownTimer(
      duration,
      startedAt,
      stoppedAt,
      isStarted,
      this.handleDisplayCountdownTimer.bind(this)
    )
  }

  play() {
    this.timerReflex('play')
  }

  pause() {
    this.timerReflex('pause')
  }

  reset() {
    this.timerReflex('reset')
  }

  handleDisplayCountdownTimer(timer) {
    if (timer.getTimer() <= 30 && !this.timerTarget.classList.contains('text-danger')) {
      this.timerTarget.classList.remove('text-warning')
      this.timerTarget.classList.add('text-danger')
    }

    this.timerTarget.innerText = timer.toString()
  }

  timerReflex(state) {
    this.stimulate('Operator::TalkCountdownTimerReflex#timer', this.element, {
      state: state,
    })
  }

  receive(res) {
    let { state } = res.detail
    let { duration } = this.element.dataset

    if (!this.countdownTimer || this.countdownTimer.duration != duration) {
      this._initCountdownTimer()
    }

    switch (state) {
      case 'play':
        this.timerTarget.classList.add('text-warning')
        this.countdownTimer.start()
        break;
      case 'pause':
        this.timerTarget.classList.remove('text-warning')
        this.countdownTimer.stop()
        break;
      case 'reset':
        this.timerTarget.classList.remove('text-warning', 'text-danger')
        this.countdownTimer.reset()
        break
      default:
        break
    }
  }
}
