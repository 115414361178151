import ApplicationController from '../application_controller'
import notify from 'components/notify'

export default class extends ApplicationController {
  static targets = ['modalNote', 'noteForm', 'noteContent']

  initialize() {
    this._createNoteURL
    this._profileable
    this.modalNote
  }

  setCreateNoteURL(url) {
    this._createNoteURL = url
  }

  getCreateNoteURL() {
    return this._createNoteURL
  }

  setProfileable(profileableType, profileableId) {
    this._profileable = {
      profileable_type: profileableType,
      profileable_id: profileableId,
    }
  }

  getProfileable() {
    return this._profileable
  }

  inputNoteAutoHeight(event) {
    this.noteContentTarget.style.height = 'auto'
    this.noteContentTarget.style.height = `${
      this.noteContentTarget.scrollHeight
    }px`
  }

  openNoteModal(event) {
    event.preventDefault()

    this.noteContentTarget.value = ''
    var target = event.currentTarget
    var { url, profileableId, profileableType } = { ...target.dataset }

    this.setCreateNoteURL(url)
    this.setProfileable(profileableType, profileableId)
    target.blur()
    this.modalNote = this.modalController().modal(this.modalNoteTarget)
    this.modalNote.show({
      backdrop: 'static',
    })
  }

  createNote(event) {
    event.preventDefault()

    var self = this
    this.request(this.getCreateNoteURL(), 'POST', {
      state_note: Object.assign(
        this.getProfileable(),
        {
          content: this.noteContentTarget.value,
        }
      )
    })
      .then((response) => {
        var data = response.data
        notify(
          {
            message: data.message,
          },
          {
            type: 'success',
          }
        )

        self.modalNote.hide()
      })
      .catch((response) => {
        notify(response.toString(), {
          type: 'danger',
        })
      })
  }
}
