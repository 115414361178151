import ApplicationController from './application_controller'
import Loader from '../components/loader'
import { buildURL } from '../helpers/filter_helper'

export default class extends ApplicationController {
  static targets = ['searchInput']

  connect() {
    this.contentElement = document.querySelector('.paginate-index-cards')
    this.data.set('filterParams', window.location.search.substring(1))
  }

  updateParams(event) {
    this.data.set('filterParams', event.detail.filterParams)
    const newParams = new URLSearchParams(event.detail.filterParams)
    if (newParams.has('keyword')) {
      this.searchInputTarget.value = newParams.get('keyword')
    } else {
      this.searchInputTarget.value = ''
    }
  }

  search(event) {
    if (event.keyCode != 13) return
    event.preventDefault()

    const keyword = this.searchInputTarget.value
    if (keyword.length === 0) {
      this.makeRequest()
      return
    }

    if (keyword.length < 3) return

    if (this.data.get('ajax-request')) {
      this.setLoader()
      this.makeRequest()
    } else {
      this.refreshPage()
    }
  }

  setLoader() {
    this.contentElement.innerHTML = Loader()
  }

  makeRequest() {
    this.disableInput()
    const url = this.requestURL()
    const query = url.split('?')[1] || ''
    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.enableInput()
        this.contentElement.innerHTML = data.result
        this.dispatchEvent(query, data.next_page_url, this.resultsPresent(data.result) && data.load_more)
      })
      .catch(error => {
        this.enableInput()
        console.error(error)
      })
  }

  refreshPage() {
    const dayParam = new URL(window.location.href).searchParams.get('day')
    let newUrl = this.requestURL()
    if (dayParam && !newUrl.match(/&day=/)) {
      newUrl +=`&alias=schedule&day=${dayParam}`
    }
    window.location.href = newUrl
  }

  dispatchEvent(params, url, more) {
    const event = new CustomEvent('onParamsChanged', {
      detail: {
        filterParams: params,
        nextUrl: url,
        loadMore: more
      }
    })
    window.dispatchEvent(event)
  }

  requestURL() {
    let filterParams = new URLSearchParams(this.data.get('filterParams'))
    filterParams.delete('keyword')
    return buildURL(this.data.get('url'), filterParams.toString(), this.searchInputTarget.value)
  }

  resultsPresent(result) {
    return result.replace(/^\s+|\s+$/g, '').length > 0
  }

  disableInput() {
    this.searchInputTarget.disabled = true
  }
  enableInput() {
    this.searchInputTarget.disabled = false
  }
}
