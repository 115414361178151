import ApplicationController from './application_controller'
import Loader from '../components/loader'
import { filterOptions, paramsFromUrl, buildURL } from '../helpers/filter_helper'

export default class extends ApplicationController {
  static targets = ['filterButton', 'searchInput', 'checkboxContainer', 'component']

  connect() {
    this.contentElement = document.querySelector(this.data.get('content-selector'))
    this.checkOptionsFromParams()
  }

  filter(event) {
    event.preventDefault()
    const query = this.prepareQueryString()
    if (this.data.get('ajax-request')) {
      this.setLoader()
      this.updateBrowserURL(query)
      this.makeRequest(query)
    } else {
      this.refreshPage(query)
    }
  }

  toggleFilterTab(event) {
    event.preventDefault();
    this.componentTarget.classList.toggle('open');
  }

  clearFilters(event) {
    event.preventDefault()
    this.refreshPage('')
  }

  dispatchEvent(params, url, more) {
    const event = new CustomEvent('onParamsChanged', {
      detail: {
        filterParams: params,
        nextUrl: url,
        loadMore: more
      }
    })
    window.dispatchEvent(event)
  }

  prepareQueryString() {
    const checked = this.checkboxContainerTarget.querySelectorAll('.cb-filter:checked')
    return new URLSearchParams(filterOptions(checked)).toString()
  }

  setLoader() {
    this.contentElement.innerHTML = Loader()
  }

  makeRequest(query) {
    fetch(buildURL(this.urlWithoutSearch(), query, ''))
      .then(response => response.json())
      .then(data => {
        this.contentElement.innerHTML = data.result
        this.dispatchEvent(query, data.next_page_url, this.resultsPresent(data.result) && data.load_more)
      })
      .catch(error => {
        console.error(error)
      })
  }

  refreshPage(query) {
    let newUrl = buildURL(this.urlWithoutSearch(), query, '');
    window.location.href = newUrl;
  }

  checkOptionsFromParams() {
    const optionsToCheck = paramsFromUrl(decodeURIComponent(window.location.search))
    Object.entries(optionsToCheck).forEach(([type, values]) => {
      values.forEach(value => {
        const element = document.querySelector(`input[name='${type}'][value='${value}']`)
        if (element) {
          this.element.checked
        }
      })
    })
  }

  urlWithoutSearch() {
    return this.data.get('url').split('?')[0]
  }

  resultsPresent(result) {
    return result.replace(/^\s+|\s+$/g, '').length > 0
  }

  updateBrowserURL(query) {
    const baseUrl = window.location.href.split('?')[0]
    window.history.pushState('', '', baseUrl + '?' + query)
  }
}
