import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import axios from "axios";
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  beforeReflex(element, reflex) {
    document.body.classList.add("wait");
  }

  reflexSuccess(element, reflex, error) {
    // show success message etc...
  }

  reflexError(element, reflex, error) {
    // show error message etc...
  }

  afterReflex(element, reflex) {
    document.body.classList.remove("wait");
    const focusElement = this.element.querySelector("[autofocus]");
    if (focusElement) {
      focusElement.focus();

      // shenanigans to ensure that the cursor is placed at the end of the existing value
      const value = focusElement.value;
      focusElement.value = "";
      focusElement.value = value;
    }
  }

  request(url, method, data = {}) {
    if (method.match(/^GET$/i)) {
      var params = Object.assign(data, { format: "json" });

      return axios.get(url, { params: params });
    } else {
      var options = {
        url: url,
        data: data,
        method: method,
        headers: {
          "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]')
            .content,
        },
        params: { format: "json" },
      };

      return axios(options);
    }
  }

  modalController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-target='modal.modal']"),
      "modal"
    );
  }
}
