export const filterOptions = (checkedOptions) => {
  let query = Array.from(checkedOptions).reduce(checkedOptionValues, '')
  query += keywordSearch()
  return query
}

export const paramsFromUrl = (queryString) => {
  if (queryString.length == 0) return {}

  const params = queryString.split('?')[1]
  let pairs = params.split('&')
  if (pairs.length === 0) return {}

  return pairs.reduce((accumulator, element) => {
    const [key, value] = element.split('=')
    createOrAddToKey(accumulator, key, value)
    return accumulator
  }, {})
}

export const buildURL = (basePath, filterQuery, keyword) => {
  let newPath = basePath
  newPath += containsQueryString(basePath) ? '&' : '?'
  newPath += filterQuery
  if (keyword.length > 0) {
    newPath += `&keyword=${keyword}`
  }
  return newPath
}

const containsQueryString = (path) => {
  return path.indexOf('?') !== -1
}

const keywordSearch = () => {
  const searchElement = document.querySelector('.filter-search-input')
  if (searchElement) {
    return `&keyword=${searchElement.value}`
  } else {
    return ''
  }
}

const checkedOptionValues = (accumulator, element) => {
  accumulator += filterKey(element.dataset.type) + element.value + '&'
  return accumulator
}

const createOrAddToKey = (object, key, value) => {
  if (object.hasOwnProperty(key)) {
    object[key].push(value)
  } else {
    object[key] = [value]
  }
  return object
}

const filterKey = (type) => {
  switch (type) {
    case 'exhibitor_category':
      return 'exhibitor_categories[]='
    case 'agenda_category':
      return 'agenda_categories[]='
    default:
      return `${type}s[]=`
  }
}
