import Trix from "trix";
import { hexToRgb } from "./utils";

// I18n translation
const i18nToolbars = I18n.t("action_text.toolbars");
for (const [key, value] of Object.entries(i18nToolbars)) {
  Trix.config.lang[key] = value;
}

const FG_COLORS = [
  hexToRgb($tenantPrimaryColor),
  hexToRgb($tenantSecondaryColor),
  hexToRgb($eventPrimaryColor),
  hexToRgb($eventSecondaryColor),
  "rgb(136, 118, 38)",
  "rgb(21, 21, 21)",
  "rgb(207, 0, 0)",
  "rgb(216, 28, 170)",
  "rgb(144, 19, 254)",
  "rgb(5, 98, 185)",
  "rgb(17, 138, 15)",
  "rgb(148, 82, 22)",
  "rgb(102, 102, 102)",
].filter((item) => !!item);

addEventListener("trix-before-initialize", () => {
  addHeadingAttributes();
  addUnderlineAttributes();
  addForegroundColorAttributes();
  addBackgroundColorAttributes();
});

addEventListener("trix-initialize", function (event) {
  new RichText(event.target);
});

addEventListener("trix-action-invoke", function (event) {
  if (event.actionName == "x-horizontal-rule") insertHorizontalRule();

  function insertHorizontalRule() {
    event.target.editor.insertAttachment(buildHorizontalRule());
  }

  function buildHorizontalRule() {
    return new Trix.Attachment({
      content: "<hr>",
      contentType: "vnd.rubyonrails.horizontal-rule.html",
    });
  }
});

class RichText {
  constructor(element) {
    this.element = element;

    this.insertHeadingElements();
    this.insertColorElements();
  }

  insertHeadingElements() {
    this.removeOriginalHeadingButton();
    this.insertNewHeadingButton();
    this.insertHeadingDialog();
  }

  removeOriginalHeadingButton() {
    var headingBtn =
      '<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" ' +
      'data-trix-attribute="heading1" title="' +
      I18n.t("action_text.toolbars.heading1") +
      '" tabindex="-1">' +
      I18n.t("action_text.toolbars.heading1") +
      "</button>";

    if (headingBtn === this.originalHeadingButton.outerHTML) {
      this.buttonGroupBlockTools.removeChild(this.originalHeadingButton);
    } else {
      this.buttonGroupBlockTools.firstChild.remove();
    }
  }

  insertNewHeadingButton() {
    this.buttonGroupBlockTools.insertAdjacentHTML(
      "afterbegin",
      this.headingButtonTemplate
    );
  }

  insertHeadingDialog() {
    this.dialogsElement.insertAdjacentHTML(
      "beforeend",
      this.dialogHeadingTemplate
    );
  }

  insertColorElements() {
    this.insertUnderlineAndColorButton();
    this.insertDialogColor();
  }

  insertUnderlineAndColorButton() {
    var colorBtn =
      '<button type="button" class="trix-button trix-button--icon trix-button--icon-color" ' +
      'data-trix-action="x-color" title="' +
      I18n.t("action_text.toolbars.color") +
      '" tabindex="-1">' +
      I18n.t("action_text.toolbars.color") +
      "</button>";
    var underlineBtn =
      '<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" ' +
      'data-trix-attribute="underline" title="' +
      I18n.t("action_text.toolbars.underline") +
      '" tabindex="-1">>U</button>';

    if (colorBtn == this.buttonGroupTextTools.lastChild.outerHTML) {
      this.buttonGroupTextTools.lastChild.remove();
      if (
        underlineBtn == this.buttonGroupTextTools.lastElementChild.outerHTML
      ) {
        this.buttonGroupTextTools.lastElementChild.remove();
      }
    }
    this.buttonGroupTextTools.insertAdjacentHTML(
      "beforeend",
      this.buttonUnderline
    );
    this.buttonGroupTextTools.insertAdjacentHTML(
      "beforeend",
      this.colorButtonTemplate
    );
  }

  insertDialogColor() {
    this.dialogsElement.insertAdjacentHTML(
      "beforeend",
      this.dialogColorTemplate
    );
  }

  get buttonGroupBlockTools() {
    return this.toolbarElement.querySelector(
      "[data-trix-button-group=block-tools]"
    );
  }

  get buttonGroupTextTools() {
    return this.toolbarElement.querySelector(
      "[data-trix-button-group=text-tools]"
    );
  }

  get dialogsElement() {
    return this.toolbarElement.querySelector("[data-trix-dialogs]");
  }

  get originalHeadingButton() {
    return this.toolbarElement.querySelector("[data-trix-attribute=heading1]");
  }

  get quoteButton() {
    return this.toolbarElement.querySelector("[data-trix-attribute=quote]");
  }

  get toolbarElement() {
    return this.element.toolbarElement;
  }

  get headingButtonTemplate() {
    return `<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-action="x-heading" title="${I18n.t(
      "action_text.toolbars.heading1"
    )}" tabindex="-1">${I18n.t("action_text.toolbars.heading1")}</button>`;
  }

  get colorButtonTemplate() {
    return `<button type="button" class="trix-button trix-button--icon trix-button--icon-color" data-trix-action="x-color" title="${I18n.t(
      "action_text.toolbars.color"
    )}" tabindex="-1">${I18n.t("action_text.toolbars.color")}</button>`;
  }

  get buttonUnderline() {
    return `<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" title="${I18n.t(
      "action_text.toolbars.underline"
    )}" tabindex="-1">U</button>`;
  }

  get dialogHeadingTemplate() {
    return `
      <div class="trix-dialog trix-dialog--heading" data-trix-dialog="x-heading" data-trix-dialog-attribute="x-heading">
        <div class="trix-dialog__link-fields">
          <input type="text" name="x-heading" class="trix-dialog-hidden__input" data-trix-input>
          <div class="trix-button-group">
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading1">H1</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading3">H3</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading4">H4</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading5">H5</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading6">H6</button>
          </div>
        </div>
      </div>
    `;
  }

  get dialogColorTemplate() {
    return `
      <div class="trix-dialog trix-dialog--color" data-trix-dialog="x-color" data-trix-dialog-attribute="x-color">
        <div class="trix-dialog__link-fields">
          <input type="text" name="x-color" class="trix-dialog-hidden__input" data-trix-input>
          <div class="trix-button-group">
            ${FG_COLORS.map((color, i) => {
              return `<button
                  type="button"
                  class="trix-button trix-button--dialog"
                  data-trix-attribute="fgColor${i + 1}"
                  data-trix-method="hideDialog"
                  style="color: ${color} !important"
                ></button>`;
            }).join("")}
          </div>
        </div>
      </div>
    `;
  }
}

function addHeadingAttributes() {
  Array.from([1, 3, 4, 5, 6]).forEach((tagName) => {
    Trix.config.blockAttributes[`heading${tagName}`] = {
      tagName: `h${tagName}`,
      terminal: true,
      breakOnReturn: true,
      group: false,
    };
  });
}

function addForegroundColorAttributes() {
  Array.from(FG_COLORS).forEach((color, i) => {
    Trix.config.textAttributes[`fgColor${i + 1}`] = {
      style: {
        color: color,
      },
      inheritable: true,
      parser: (e) => e.style.color == color,
    };
  });
}

function addBackgroundColorAttributes() {
  Array.from([
    "rgb(250, 247, 133)",
    "rgb(255, 240, 219)",
    "rgb(255, 229, 229)",
    "rgb(255, 228, 247)",
    "rgb(242, 237, 255)",
    "rgb(225, 239, 252)",
    "rgb(228, 248, 226)",
    "rgb(238, 226, 215)",
    "rgb(242, 242, 242)",
  ]).forEach((color, i) => {
    Trix.config.textAttributes[`bgColor${i + 1}`] = {
      style: {
        backgroundColor: color,
      },
      inheritable: true,
      parser: (e) => e.style.backgroundColor == color,
    };
  });
}

function addUnderlineAttributes() {
  Trix.config.textAttributes.underline = {
    style: {
      textDecoration: "underline",
    },
    inheritable: true,
    parser: (e) => e.style.textDecoration == "underline",
  };
}

$(function () {
  $(".trix-content a").click(function (e) {
    $(this).attr("target", "_blank");
  });
});
