import ApplicationController from './application_controller'
import CableReady from 'cable_ready'
import consumer from 'channels/consumer'

export default class extends ApplicationController {
  static targets = ['livestream', 'pollingForm', 'pollingData']

  connect() {
    super.connect()

    if (this.hasLivestreamTarget) {
      this.connectLivestreamsChannel()
    }
  }

  disconnect() {
    if (this.hasLivestreamTarget) {
      this.livestreamTarget.remove()
      this.channel && this.channel.unsubscribe()
    }
  }

  connectLivestreamsChannel() {
    let { livestreamNumberId } = this.livestreamTarget.dataset

    this.channel = consumer.subscriptions.create(
      {
        channel: 'LivestreamChannel',
        id: livestreamNumberId,
      },
      {
        connected: () => {
          console.log('websocket connected')
        },
        received: (data) => {
          if (data.cableReady) {
            CableReady.perform(data.operations)
          }
        },
      }
    )
  }

  buildOptionValuefromSelectBox(selectedItem) {
    let options = this.pollingFormTarget.childNodes;
    let optionsValue = [];

    if(this.pollingFormTarget.dataset.fieldType == 'radio' && selectedItem.checked) {
      for (let option of options) {
        option.firstElementChild.checked = false;
        if (option.firstElementChild.isSameNode(selectedItem)) {
          option.firstElementChild.checked = true;
          optionsValue = option.lastElementChild.innerText;
        }
      }

      return optionsValue;
    }

    for (let option of options) {
      if (option.firstElementChild.checked) {
        optionsValue.push(option.lastElementChild.innerText);
      }
    }

    return optionsValue.join(',');
  }

  onSelectOption(e) {
    this.request('/user_pollings', 'POST', {
      user_polling: {
        additional_field_id: this.pollingDataTarget.dataset.additionalFieldId,
        options: this.buildOptionValuefromSelectBox(e.currentTarget)
      }
    })
      .catch(err => {
        document.getElementById('flash-messages').innerHTML = `<div class='flash'>${I18n.t('flash.request_error')}</div>`
      })
  }

  reload() {
    this.stimulate();
  }
}
