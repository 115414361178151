$(function() {
  if ($('.sidebar').length < 1) {
    return;
  }

  $('.sidebar-toggle').on('click tap', function(e) {
    let button = e.target;
    let label = button.textContent;
    let hideLabel = $(button).data('hide-label')
    let showLabel = $(button).data('show-label')

    if (label === hideLabel) {
      $('.sidebar').removeClass('d-flex');
      $('.sidebar').hide('d-none');

      $('.content').parent().removeClass('col-lg-9');

      button.textContent = showLabel;
      $(button).addClass('float-right');
    } else {
      $('.sidebar').removeClass('d-none');
      $('.sidebar').addClass('d-flex');

      $('.content').parent().addClass('col-lg-9');

      button.textContent = hideLabel;
      $(button).removeClass('float-right');
    }
  });
});
