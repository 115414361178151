import ApplicationController from "../application_controller"
import notify from "components/notify"

export default class extends ApplicationController {
  static targets = ["stateNoteSection"]

  makeComplete(event) {
    event.preventDefault()

    var confirmMsg = ""
    var element = event.currentTarget
    element.blur()

    confirmMsg =
      element.dataset.completed === "true" || element.dataset.completed === true
        ? I18n.t("operator.confirm_messages.livestream_incompleted")
        : I18n.t("operator.confirm_messages.livestream_completed")

    if (confirm(confirmMsg)) {
      this.request(element.dataset.url, "PUT")
        .then((response) => {
          var msg = response.data.completed
            ? I18n.t("operator.flash.session_completed")
            : I18n.t("operator.flash.session_not_completed")
          element.dataset.completed = response.data.completed

          if (response.data.completed) {
            element.classList.add("btn-primary")
            element.classList.remove("btn-info")
          } else {
            element.classList.add("btn-info")
            element.classList.remove("btn-primary")
          }

          notify({ title: I18n.t("notify_status.successful"), message: msg }, { type: "success" })
        })
        .catch((response) => {
          notify({ title: response.name, message: response.message }, { type: "danger" })
        })
    }
  }

  openNoteModal(event) {
    this.noteController().openNoteModal(event)
  }

  noteController() {
    return this.application.getControllerForElementAndIdentifier(this.stateNoteSectionTarget, "operator--state-notes")
  }
}
