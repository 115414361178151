import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['modalSpeaker', 'speakerAvatar', 'speakerName', 'speakerJob', 'speakerCompany', 'speakerBio']

  showModal(event) {
    event.preventDefault()

    var {
      avatar: speakerAvatarUrl,
      jobTitle: speakerJobTitle,
      name: speakerName,
      company: speakerCompany,
      bio: speakerBio
    } = { ...event.currentTarget.dataset }

    this.speakerAvatarTarget.src = speakerAvatarUrl || ''
    this.speakerNameTarget.innerText = speakerName || ''
    this.speakerJobTarget.innerText = speakerJobTitle || ''
    this.speakerCompanyTarget.innerText = speakerCompany || ''
    this.speakerBioTarget.innerHTML = speakerBio || ''

    this.modalController().openModal(this.modalSpeakerTarget)
  }
}
