export const stringToHTML = (str) => {
  var tmpDOM = document.createElement('div')
  tmpDOM.innerHTML = str.trim().replaceAll('\n', '').replace(/\s+/g, ' ')
  return tmpDOM.firstChild
}

export const createListElementFromString = (str) => {
  var nodes = [],
    tmpDOM = document.createElement('div')

  tmpDOM.innerHTML = str.trim()
  for (let node of tmpDOM.childNodes) {
    nodes.push(node)
  }

  return nodes
}


export const prependList = (element, nodes = []) => {
    for (let node of nodes) {
      element.prepend(node)
    }
    return element
  }

export const getCaretPosition = (editableDiv) => {
  var caretPos = 0,
    sel,
    range

  if (window.getSelection) {
    sel = window.getSelection()
    if (sel.rangeCount) {
      range = sel.getRangeAt(0)
      if (range.commonAncestorContainer.parentNode == editableDiv) {
        caretPos = range.endOffset
      }
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange()
    if (range.parentElement() == editableDiv) {
      var tempEl = document.createElement('span')
      editableDiv.insertBefore(tempEl, editableDiv.firstChild)
      var tempRange = range.duplicate()
      tempRange.moveToElementText(tempEl)
      tempRange.setEndPoint('EndToEnd', range)
      caretPos = tempRange.text.length
    }
  }
  return caretPos
}

export const insertStringAtPosition = (str, strInsert, pos) => {
  return [str.slice(0, pos), strInsert, str.slice(pos)].join('')
}

export const debounce = (func, wait) => {
  var timeout

  return function () {
    var context = this,
      args = arguments

    var executeFunction = function () {
      func.apply(context, args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(executeFunction, wait)
  }
}

export const observeDOM = (() => {
  var MutationObserver = window.MutationObserver || window.WebKitMutationObserver

  return function (obj, callback) {
    if (!obj || obj.nodeType !== 1) return // validation

    if (MutationObserver) {
      var obs = new MutationObserver(function (mutations, observer) {
        callback(mutations, observer)
      })
      obs.observe(obj, { childList: true })
    } else if (window.addEventListener) {
      obj.addEventListener('DOMNodeInserted', callback, false)
      obj.addEventListener('DOMNodeRemoved', callback, false)
    }
  }
})()
