import ApplicationController from '../application_controller'
import notify from 'components/notify'
import CableReady from 'cable_ready'
import consumer from 'channels/consumer'
import { setResults } from '../../components/Poll/requests';

require("chosen-js")
require("chartkick/chart.js")

export default class extends ApplicationController {
  static targets = ['livestream', 'incompletedPollings', 'completedPollings', 'createPollModal', 'createPollOptionList', 'editPollModal', 'deletePollModal', 'editPollOptionList', 'pollErrorMessages', 'incompletedPollButton', 'completedPollButton', 'pollingResult']

  initialize() {
    $('.polling-talk-select').chosen({ width: '100%' })
    this.pollingChartObject = this.initPollingGraph()
    this.isPolling = this.pollingResultTarget.hasChildNodes()
  }

  initPollingGraph(data = null) {

    const chartOptions = (pollingResultAsNumberData) => {
      return {
        library: {
          plugins: {
            tooltip: {
              callbacks: {
                  label: (context) => {
                    return `${context.raw}% (${pollingResultAsNumberData[context.label]})`
                  }
              }
            }
          }
        }
      }
    }

    if (data) {
      const pollingResultAsNumberData = data.results_as_number;
      return new Chartkick.ColumnChart("polling-result-chart", data.results_as_percent, chartOptions(pollingResultAsNumberData))
    }

    const pollingResultAsNumberData = $('.displaying-polling').data('polling-result-as-number');

    let pollingResultAsPercentData = null

    try {
      pollingResultAsPercentData = JSON.parse(this.pollingResultTarget.dataset.pollingResult)
    } catch {}

    if (pollingResultAsPercentData) {
      return new Chartkick.ColumnChart("polling-result-chart", pollingResultAsPercentData, chartOptions(pollingResultAsNumberData))
    }
  }

  connect() {
    super.connect()
    this.connectPollingChannel()

    const pollForData = this.pollingResultTarget.dataset.pollForData == "true"
    if(pollForData) {
      this.updateGraphPeriodically();
    }

    this.updateQuestionVotes();
  }

  updateGraphPeriodically() {
    const RESULTS_UPDATE_DELAY = 5000;
    this.cancelId = setInterval(() => {
      const fieldId = this.pollingResultTarget.dataset?.fieldId;
      const liveChart = document.querySelector("#polling-result-chart");
      if (fieldId && liveChart) {
        setResults(fieldId, this.initPollingGraph);
      }
    }, RESULTS_UPDATE_DELAY);
  }

  updateQuestionVotes() {
    const RESULTS_UPDATE_DELAY = 15000;
    let questionIds = []

    document.querySelectorAll('.question-info').forEach((item) => {
      questionIds.push($(item).data('question-id'));
    });

    this.fetchQuestionVotes = setInterval(() => {
      $.ajax({
        url: '/user_pollings/poll_question_votes',
        type: 'POST',
        data: { ids: questionIds },
        success: (res) => {
          const data = res.data;
          document.querySelectorAll('.question-info').forEach((item) => {
            const vote = data[$(item).data('question-id')];
            if(vote) {
              $(item).find('.total-votes').html(vote);
            }
          });
        },
        error: (error) => {
          console.log('An error occurred', error);
        }
      })
    }, RESULTS_UPDATE_DELAY);
  }

  disconnect() {
    this.channel && this.channel.unsubscribe();
    clearInterval(this.cancelId);
    clearInterval(this.fetchQuestionVotes);
  }

  afterReflex() {
    this.pollingChartObject = this.initPollingGraph()
  }

  connectPollingChannel() {
    this.channel = consumer.subscriptions.create('PollingChannel',
      {
        connected: () => {
          let { resourceId, resourceType } = this.element.dataset

          if (resourceType === 'Livestream') {
            this.channel.perform('follow_livestream', { id: resourceId, broadcast_type: 'operator' })
          } else {
            this.channel.perform('follow', { id: resourceId, type: resourceType })
          }
        },
        received: (data) => {
          if (data.cableReady) {
            CableReady.perform(data.operations)
          }
        },
      }
    )

    this.StimulusReflex = { channel: this.channel.identifier, subscription: this.channel }
  }

  updated(res) {
    let { decOptions, incOptions } = res.detail
    let newDataSource = typeof this.pollingChartObject.dataSource !== 'undefined' ? this.pollingChartObject.dataSource : {}

    for (let opt of decOptions) {
      if (newDataSource.hasOwnProperty(opt))
        newDataSource[opt] -= 1
    }

    for (let opt of incOptions) {
      if (newDataSource.hasOwnProperty(opt))
        newDataSource[opt] += 1
    }

    this.pollingChartObject = this.initPollingGraph(newDataSource)
  }

  onCompletePoll(e) {
    if (!this.isPolling) {
      return
    }

    let pollingId = e.currentTarget.dataset.pollingId
    this.stimulate('PollingsReflex#complete_poll', pollingId)
  }

  onStartPoll(e) {
    if (this.isPolling) {
      return
    }

    let pollingId = e.currentTarget.dataset.pollingId
    this.stimulate('PollingsReflex#start_poll', pollingId)
  }

  onFilterPollings(e) {
    let isCompleted = e.currentTarget.dataset.isCompleted
    isCompleted = isCompleted === 'true'
    let incompletedPollingsDom = this.incompletedPollingsTarget
    let completedPollingsDom = this.completedPollingsTarget

    if (isCompleted) {
      completedPollingsDom.classList.remove('d-none')
      incompletedPollingsDom.classList.add('d-none')

      this.completedPollButtonTarget.classList.remove('btn-secondary')
      this.incompletedPollButtonTarget.classList.remove('btn-primary')
      this.completedPollButtonTarget.classList.add('btn-primary')
    } else {
      incompletedPollingsDom.classList.remove('d-none')
      completedPollingsDom.classList.add('d-none')

      this.incompletedPollButtonTarget.classList.remove('btn-secondary')
      this.completedPollButtonTarget.classList.remove('btn-primary')
      this.incompletedPollButtonTarget.classList.add('btn-primary')
    }
  }

  onClickCreatePoll(e) {
    this.errorMessage = this.pollErrorMessagesTargets.filter(errorMessageTarget => errorMessageTarget.dataset.createPoll == "true")[0]

    this.errorMessage.classList.add("d-none")
    this.errorMessage.childNodes[0].innerHTML = ''

    this.pollModal = this.modalController().modal(this.createPollModalTarget)
    this.pollModal.show()
  }

  onClickEditPoll(e) {
    this.errorMessage = this.pollErrorMessagesTargets.filter(errorMessageTarget => errorMessageTarget.dataset.pollingId == e.target.dataset.pollingId)[0]

    this.errorMessage.classList.add("d-none")
    this.errorMessage.childNodes[0].innerHTML = ''

    $('.polling-talk-select').chosen({ width: '100%' })

    this.editPollModal = this.modalController().modal(this.editPollModalTargets.filter(modal => modal.dataset.pollingId == e.target.dataset.pollingId)[0])
    this.editPollModal.show()
  }

  onClickDeletePoll(e) {
    this.deletePollModal = this.modalController().modal(this.deletePollModalTargets.filter(modal => modal.dataset.pollingId == e.target.dataset.pollingId)[0])
    this.deletePollModal.show()
  }

  onClickAddOption(e) {
    let optionList = e.target.parentElement.querySelector(".option-list")
    let index = optionList.childNodes.length

    let new_field = document.createElement("input");
    new_field.type = "text"
    new_field.className = "form-control"
    new_field.placeholder = `Option ${index + 1}`
    new_field.id = `polling_additional_fields_attributes_0_option_list[${index}]`
    new_field.name = `polling[additional_fields_attributes][0][option_list[${index}]]`

    optionList.appendChild(new_field)
  }

  convertOptionFieldsToOptionString(optionList) {
    let fieldIndex = optionList.dataset.fieldIndex
    let optionsValue = []

    for (let op of optionList.childNodes) {
      if (op.value && op.value.length > 0) {
        optionsValue.push(op.value)
      }
    }

    return { fieldIndex, optionsValue }
  }

  onDeletePolling(e) {
    let polling_id = e.target.dataset.pollingId
    let url = $('#new_polling').attr('action') + `/${polling_id}`

    this.request(url, 'DELETE')
      .then(result => {
        this.deletePollModal.hide()
        let data = result.data
        if (data.success) {
          this.stimulate('PollingsReflex#remove_poll', data.polling_id)
          notify(
            {
              message: data.message,
            },
            {
              type: 'success',
            }
          )
        } else {
          console.log(data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  onSavePolling(e) {
    let polling_id = e.target.dataset.pollingId

    let { resourceId } = this.element.dataset
    let optionList = this.editPollOptionListTargets.filter(editPollOptionList => editPollOptionList.dataset.pollingId == polling_id)[0]
    let { fieldIndex, optionsValue } = this.convertOptionFieldsToOptionString(optionList)

    const currentEditPollModal = this.editPollModalTargets.filter(modal => modal.dataset.pollingId == polling_id)[0]

    let hiddenOptionDom = currentEditPollModal.getElementsByClassName(`options[${fieldIndex}]`)
    hiddenOptionDom[0].value = optionsValue.join(', ')

    let that = this
    let url = $('#new_polling').attr('action') + `/${polling_id}`

    let mountableType = currentEditPollModal.querySelector('#polling_mountable_id').value ? 'Talk' : 'Livestream'
    let mountableId = currentEditPollModal.querySelector('#polling_mountable_id').value || resourceId
    let fieldType = currentEditPollModal.querySelector('#polling_additional_fields_attributes_0_field_type').value
    let fieldLabel = currentEditPollModal.querySelector('#polling_additional_fields_attributes_0_field_label').value
    let options = currentEditPollModal.querySelector('#polling_additional_fields_attributes_0_options').value
    let additional_field_id = optionList.dataset.additionalFieldId

    let data = {
      polling: {
        mountable_type: mountableType,
        mountable_id: mountableId,
        additional_fields_attributes: {
            id: additional_field_id,
            field_label: fieldLabel,
            field_type: fieldType,
            options: options
        }
      }
    }

    this.errorMessage = this.pollErrorMessagesTargets.filter(errorMessageTarget => errorMessageTarget.dataset.pollingId == polling_id)[0]
    this.request(url, 'PATCH', data)
      .then(result => {
        let data = result.data
        if (data.success) {
          that.editPollModal.hide()
          that.stimulate('PollingsReflex#edit_poll', data.polling_id)
          $(`#edit_polling_${polling_id}`).trigger('reset')
          notify(
            {
              message: data.message,
            },
            {
              type: 'success',
            }
          )
        } else {
          that.errorMessage.classList.remove('d-none')
          that.errorMessage.childNodes[0].innerHTML = data.error_messages.map(message => {
            return `<li class="text-white">${message}</li>`
          }).join('')
        }
      })
      .catch(err => {
        console.log(err)
        that.errorMessage.classList.remove('d-none')
        that.errorMessage.childNodes[0].innerHTML = `<li class="text-white">${I18n.t('flash.request_error')}</li>`
      })
  }

  onAddNewPolling(e) {
    let { resourceId } = this.element.dataset
    let optionList = this.createPollOptionListTarget
    let { fieldIndex, optionsValue } = this.convertOptionFieldsToOptionString(optionList)

    let hiddenOptionDom = this.createPollModalTarget.getElementsByClassName(`options[${fieldIndex}]`)
    hiddenOptionDom[0].value = optionsValue.join(', ')

    let that = this
    let url = $('#new_polling').attr('action')

    let mountableType = this.createPollModalTarget.querySelector('#polling_mountable_id').value ? 'Talk' : 'Livestream'
    let mountableId = this.createPollModalTarget.querySelector('#polling_mountable_id').value || resourceId
    let fieldType = this.createPollModalTarget.querySelector('#polling_additional_fields_attributes_0_field_type').value
    let fieldLabel = this.createPollModalTarget.querySelector('#polling_additional_fields_attributes_0_field_label').value
    let options = this.createPollModalTarget.querySelector('#polling_additional_fields_attributes_0_options').value

    let data = {
      polling: {
        mountable_type: mountableType,
        mountable_id: mountableId,
        additional_fields_attributes: {
          '0': {
            field_label: fieldLabel,
            field_type: fieldType,
            options: options
          }
        }
      }
    }

    this.errorMessage = this.pollErrorMessagesTargets.filter(errorMessageTarget => errorMessageTarget.dataset.createPoll == "true")[0]
    this.request(url, 'POST', data)
      .then(result => {
        let data = result.data
        if (data.success) {
          that.stimulate('PollingsReflex#add_poll', data.polling_id)
          that.pollModal.hide()
          $('#new_polling').trigger('reset')
          notify(
            {
              message: data.message,
            },
            {
              type: 'success',
            }
          )
        } else {
          that.errorMessage.classList.remove('d-none')
          that.errorMessage.childNodes[0].innerHTML = data.error_messages.map(message => {
            return `<li class="text-white">${message}</li>`
          }).join('')
        }
      })
      .catch(err => {
        that.errorMessage.classList.remove('d-none')
        that.errorMessage.childNodes[0].innerHTML = `<li class="text-white">${I18n.t('flash.request_error')}</li>`
      })
  }

  pollStarted(e) {
    this.isPolling = true
    let { detail } = e
    let startPollButtons = document.getElementsByClassName('start-poll-btn')
    for(let element of startPollButtons) {
      element.disabled = true
    }

    this.pollingResultTarget.outerHTML = detail.displayingPollingHtml
    this.pollingChartObject = this.initPollingGraph()
  }

  pollCompleted(e) {
    this.isPolling = false
    let startPollButtons = document.getElementsByClassName('start-poll-btn')
    for(let element of startPollButtons) {
      element.disabled = false
    }
  }

  onClickDisplayResults(e) {
    let pollingId = e.currentTarget.dataset.pollingId
    this.stimulate('PollingsReflex#update_results', pollingId, true)
  }

  onClickHideResults(e) {
    let pollingId = e.currentTarget.dataset.pollingId
    this.stimulate('PollingsReflex#update_results', pollingId, false)
  }
}
