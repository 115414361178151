/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix");
require("@rails/activestorage").start();
require("@rails/actiontext");
require("bootstrap");

import "controllers";
import "select2";
import "shared";
import "channels";

require('../src/dc-i18n');
//
//require('src/server_rendering');
// Render this in react pack instead.
// AWS CloudFront has a max file size of 10MB to allow CDN compression
// and this is almost 9MB so it's not possible to render this in the core pack.
//
require("../src/shared");
require('../src/emoji');
require('../src/action_text');
require('../shared/pdf_presenter');

import '../src/browser_timezone_cookie';
