import ApplicationController from './application_controller'
import { EmojiButton } from '@joeattardi/emoji-button'
import {
  debounce,
  stringToHTML,
  createListElementFromString,
  prependList,
  getCaretPosition,
  insertStringAtPosition,
  observeDOM,
} from 'helpers/dom_helper'
import { generateUUID } from 'helpers/random_helper'
import moment from 'moment'
import consumer from 'channels/consumer'
import notify from '../components/notify'
import MessageItem from '../components/message_item'

export default class extends ApplicationController {
  static targets = ['messageList', 'messageContent', 'displayImages']

  initialize() {
    this._initEmojiButton()
    this._initChatMessage()
    this.cursorPosition = 0
    this.images = []
    this.hasNextPage = true
    this.isPaginationProcessing = true
    this.loadingTimeout = 100
    this.isInitialized = false
    this.lastId = null
    this.isConnected = false
    this.onMessageListUpdated()
  }

  connect() {
    super.connect()
  }

  disconnect() {
    this.chatChannel && this.isConnected && this.chatChannel.unsubscribe()
  }

  _initEmojiButton() {
    this.emojiButton = new EmojiButton({
      position: 'top-start',
      rootElement: this.element.querySelectorAll(
        '.component-chat-emojipicker'
      )[0],
    })

    this.emojiButton.on('emoji', (selection) => {
      var newText = insertStringAtPosition(
        this.messageContentTarget.innerText, selection.emoji, this.cursorPosition
      )
      this.messageContentTarget.innerText = newText
    })
  }

  _initChatMessage() {
    let { id, type } = {...this.element.dataset}
    let actionCableParams = {
      channel: 'MessageChannel',
      id,
      type,
    }

    this.messageListTarget.innerHTML = ''
    this.chatChannel =
      consumer.subscriptions.findAll(JSON.stringify(actionCableParams))[0] ||
      consumer.subscriptions.create(actionCableParams, {
        connected: () => {
          this.isConnected = true;
          if (!this.isInitialized) {
            this._loadMessages(() => {
              this._removeLoadingWrapper()
              this._chatListScrollToBottom()
            })
          }

          this.isInitialized = true
        },
        disconnected: () => {
          this.chatChannel && this.chatChannel.perform('unsubscribed')
        },
        received: (data) => {
          if (data.destroyed) {
            let messageID = data.message_id
            let messageDOM = document.querySelector(`.component-chat-item[data-message-hexdigest="${messageID}"]`)

            return messageDOM && messageDOM.remove()
          }

          let { message, uuid } = data
          let currentUserId = parseInt(this.element.dataset.ownerId)
          let messageItem

          if (currentUserId === message.owner.id) {
            messageItem = document.querySelector(`.component-chat-item[processing-uuid="${uuid}"]`)
            if (messageItem) {
              messageItem.classList.remove('processing')
              messageItem.setAttribute('data-message-id', message.id)
              messageItem.setAttribute('data-message-hexdigest', data.message_hexdigest)
              messageItem.removeAttribute('processing-uuid')
              return true
            }
          }

          messageItem = MessageItem(message, currentUserId)
          this.messageListTarget.insertAdjacentHTML('beforeend', messageItem)
          this._chatListScrollToBottom()
        },
      })
  }

  onMessageListUpdated = () => {
    var self = this

    observeDOM(this.messageListTarget, (mutationsList, observer) => {
      for(let mutation of mutationsList) {
        if (mutation.type !== 'childList') {
          return
        }

        if (mutation.removedNodes.length) {
          self.onPagination()
        }
      }
    })
  }

  _removeLoadingWrapper = () => {
    this.element.classList.remove('loading')
  }

  _loadMessages(callback = null) {
    let { id, type, groupType, messagesUrl } = this.element.dataset
    let params = {
      resource_type: type,
      resource_id: id,
      group_type: groupType,
      last_id: this.lastId
    }

    this.request(messagesUrl, 'GET', params)
      .then((response) => {
        let { html, has_next_page, last_id } = response.data
        let messagesList = createListElementFromString(html)

        this.hasNextPage = has_next_page
        this.isPaginationProcessing = false
        this.lastId = last_id
        prependList(this.messageListTarget, messagesList)

        if (callback) {
          callback()
        }
      })
      .catch((error) => {
        notify(error.message, {
          type: 'danger',
        })
      })
      .then(() => {
        this.isPaginationProcessing = false
      })
  }

  _chatListScrollToBottom() {
    if (this.messageListTarget.scrollHeight > this.messageListTarget.clientHeight) {
      setTimeout(() => {
        this.messageListTarget.scrollTo({
          top: this.messageListTarget.scrollHeight,
        })
      }, this.loadingTimeout)
    }
  }

  _renderAttachment() {
    var containerAttachments = document.createElement('div')
    containerAttachments.classList.add('component-chat-item-attachments')

    this.images.forEach((image) => {
      var dataSrc = [`data:${image['type']};base64,`, image['data']].join('')

      var link = document.createElement('a')
      link.href = dataSrc
      link.setAttribute('data-fancybox', 'gallery')
      link.target = '_blank'

      var imgTag = document.createElement('img')
      imgTag.src = dataSrc
      link.appendChild(imgTag)

      var figure = document.createElement('figure')
      figure.classList.add('component-chat-item-image')
      figure.appendChild(link)
      containerAttachments.appendChild(figure)
    })

    return containerAttachments
  }

  _renderMessage(msgText, uuid) {
    let msgNode = stringToHTML(this.element.dataset.messageItem)
    msgNode.classList.add('processing')
    msgNode.setAttribute('processing-uuid', uuid)
    let msgAttachments = msgNode.querySelector('.component-chat-item-attachments')
    let msgContent = msgNode.querySelector('.component-chat-item-message')
    let msgTime = msgNode.querySelector('.component-chat-item-time time')

    msgAttachments.replaceWith(this._renderAttachment())
    msgContent.appendChild(stringToHTML(`<div>${msgText}</div>`))
    msgTime.setAttribute('datetime', moment.utc().format())
    msgTime.innerText = moment().format('HH:mm')

    this.messageListTarget.appendChild(msgNode)
    this._chatListScrollToBottom()
  }

  _handleSendMessage(msg, uuid) {
    var { groupType, id: resourceID, type: resourceType } = { ...this.element.dataset }
    this.chatChannel.send({
      message: msg,
      resource_type: resourceType,
      resource_id: resourceID,
      images: this.images,
      group_type: groupType,
      uuid: uuid
    })

    this.images = []
    this.messageContentTarget.innerHTML = ''
    this.displayImagesTarget.innerHTML = ''
  }

  _imageToBase64(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject({ error: I18n.t('chat_messages.validations.messages.image.blank') })
      }

      var fileReader = new FileReader()
      fileReader.onload = (event) => {
        let dataBase64 = event.target.result.replace(/^data:image\/[a-z]+;base64,/, '')
        let data = {
          name: file.name,
          size: file.size,
          type: file.type,
          data: dataBase64
        }

        resolve(data)
      }
      fileReader.readAsDataURL(file)
    })
  }

  onPagination() {
    if (this.isPaginationProcessing || !this.hasNextPage) {
      return
    }

    if (this.hasNextPage && this.messageListTarget.scrollTop === 0) {
      var firstMessage = this.messageListTarget.children[0]

      this.isPaginationProcessing = true
      this.messageListTarget.classList.add('is-paginating')
      this._loadMessages(() => {
        this.messageListTarget.classList.remove('is-paginating')
        this.messageListTarget.scrollTo({
          top: firstMessage.offsetTop - firstMessage.clientHeight,
        })
      })
    }
  }

  onAttachment(event) {
    var promies = [],
      files = event.target.files
    for (let i = 0; i < files.length; i++) {
      let file = files[i]

      if (file.size > 5000000) {
        notify(I18n.t('chat_messages.validations.messages.image.size_invalid'), {
          type: 'danger',
        })
        continue
      }

      if (file.type.startsWith('image/')) {
        promies.push(this._imageToBase64(file))
      } else {
        notify(I18n.t('chat_messages.validations.messages.image.invalid'), {
          type: 'danger',
        })
      }
    }

    Promise.all(promies).then((values) => {
      this.images = this.images.concat(values)

      values.forEach((image) => {
        var dataSrc = [`data:${image['type']};base64,`, image['data']].join('')
        var imagePreview = document.createElement('div')
        var btnRemove = stringToHTML(`
          <button class="btn btn-remove-image" title="remove attachment">
            <span class="dc-icons ph-trash" data-action="click->chat#removeAttachment"></span>
          </button>`)
        var imgTag = document.createElement('img')
        imgTag.src = dataSrc

        imagePreview.classList.add('component-chat-image-preview')
        imagePreview.appendChild(imgTag)
        imagePreview.appendChild(btnRemove)
        this.displayImagesTarget.appendChild(imagePreview)
      })
    })
    event.target.value = ''
  }

  removeAttachment(event) {
    var imagePreview = event.target.closest('.component-chat-image-preview')
    var index = Array.from(imagePreview.parentNode.children).indexOf(imagePreview)
    imagePreview.remove()
    this.images.splice(index, 1)
  }

  handlePosition = debounce(() => {
    this.cursorPosition = getCaretPosition(this.messageContentTarget)
  },  300)

  sendMessage(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      event.stopPropagation()
      var msg = this.messageContentTarget.innerText.trim()

      if (msg.length || this.images.length) {
        let uuid = generateUUID()
        this._renderMessage(msg, uuid)
        this._handleSendMessage(msg, uuid)
      }
    }
  }

  toggleEmojiPicker(event) {
    var target = event.currentTarget
    this.emojiButton.togglePicker(target)
  }

  showDropdown(event) {
    let ele = event.currentTarget
    let dropdown = ele.closest('.dropdown')

    if (!dropdown)
      return

    let dropdownMenu = dropdown.querySelector('.dropdown-menu')

    if (!dropdownMenu)
      return

    if (dropdown.classList.contains('show')) {
      dropdown.classList.remove('show')
      dropdownMenu.classList.remove('show')
    } else {
      dropdown.classList.add('show')
      dropdownMenu.classList.add('show')
    }
  }

  hideDropdown(event) {
    var ele = event.currentTarget
    var dropdown = ele.querySelector('.dropdown')

    if (dropdown) {
      var dropdownMenu = dropdown.querySelector('.dropdown-menu')

      if (dropdown.classList.contains('show')) {
        dropdown.classList.remove('show')
        dropdownMenu.classList.remove('show')
      }
    }
  }

  removeMessage(event) {
    event.preventDefault()

    var messageNode = event.currentTarget.closest('.component-chat-item')
    var messageId = messageNode.dataset.messageId
    var confirmRemoved = confirm(I18n.t('actions.confirm_action'))

    if (confirmRemoved) {
      this.chatChannel.send({
        message_id: messageId,
        destroyed: true,
      })
    }
  }
}
