export const isArray = (dataToCheck) =>
  dataToCheck && dataToCheck.constructor === Array;

export const isObject = (dataToCheck) =>
  dataToCheck && dataToCheck.constructor === Object;

export const toSnake = (s) => {
  return s.replace(/[A-Z]/g, (char) => {
    return `_${char.toLowerCase()}`;
  });
};

export const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

export const snakeCaseKeys = (data) => {
  if (isArray(data)) {
    return data.map((datumn) => {
      return snakeCaseKeys(datumn);
    });
  } else if (isObject(data)) {
    const result = {};

    Object.entries(data).forEach(([key, value]) => {
      result[toSnake(key)] = snakeCaseKeys(value);
    });

    return result;
  } else {
    return data;
  }
};

export const camelCaseKeys = (data) => {
  if (isArray(data)) {
    return data.map((datumn) => {
      return camelCaseKeys(datumn);
    });
  } else if (isObject(data)) {
    const result = {};

    Object.entries(data).forEach(([key, value]) => {
      result[toCamel(key)] = camelCaseKeys(value);
    });

    return result;
  } else {
    return data;
  }
};

export const debounce = (cb, interval, immediate) => {
  let timeout;

  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) cb.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, interval);

    if (callNow) {
      cb.apply(context, args);
    }
  };
};

export const detectMobildeDevice = () =>
  /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const getRandom = (arr, n) => {
  let result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

export const getParameterByName = (name, url) => {
  if (!url) {
    url = window.location.href;
  }

  name = name.replace(/[\[\]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const hexToRgb = (hex) => {
  var r,
    g,
    b,
    result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    return hex;
  }

  r = parseInt(result[1], 16);
  g = parseInt(result[2], 16);
  b = parseInt(result[3], 16);
  return `rgb(${r}, ${g}, ${b})`
}
