import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['advertisement'];

  connect() {
    super.connect();

    if (this.hasAdvertisementTarget) {
      this.loadAdvertisements();
    }
  }

  disconnect() {
    if (this.refreshAdvertisementTimer) {
      clearInterval(this.refreshAdvertisementTimer);
    }
  }

  loadAdvertisements() {
    let { refreshInterval, advertisementCount, contentType, contentId } = this.advertisementTarget.dataset;

    if (advertisementCount > 1) {
      this.refreshAdvertisementTimer = setInterval(() => {
        this.fetchAdvert();
      }, refreshInterval * 60 * 1000);
    }

    this.trackAdWatch(contentType, contentId);
  }

  fetchAdvert() {
    this.request('/dcadvrts', 'GET', {
      location: 'Live Stream Page',
      id: this.advertisementTarget.dataset.objectId
    })
    .then(response => this.loadNewAdvert(response.data));
  }

  loadNewAdvert(response) {
    let existingAdvertisementId = this.advertisementTarget.dataset.contentId;

    // only display the new advertisement if it's different to the existing advertisement
    if (response.contentId !== undefined && existingAdvertisementId != response.contentId) {
      this.element.innerHTML = response.html;

      this.advertisementTarget.dataset.contentId = response.contentId;

      this.trackAdWatch(response.contentType, response.contentId);
    }
  }

  trackAdWatch(contentType, contentId) {
    let trackingPrefix = this.advertisementTarget.dataset.trackingPrefix;

    ahoyEventTracking(trackingPrefix + ' DCAD Watch', {
      contentType: contentType,
      contentId: contentId
    });
  }

  trackAdClick(event) {
    let { contentType, contentId } = event.currentTarget.dataset;
    let trackingPrefix = this.advertisementTarget.dataset.trackingPrefix;

    ahoyEventTracking(trackingPrefix + ' DCAD Click', {
      contentType: contentType,
      contentId: Number(contentId)
    });
  }

  captureLead(event) {
    event.preventDefault();

    let { eventId, leadableId } = event.currentTarget.dataset;

    this.request('/leads', 'POST', {
      event_id: eventId,
      leadable_id: leadableId,
      leadable_type: 'Sponsor'
    })
    .then(response => this.displaySuccessMessage(response.data));
  }

  displaySuccessMessage(response) {
    if (response.success) {
      this.element.querySelector('.lead-capture').classList.add('d-none');
      this.element.querySelector('.lead-capture-success').classList.remove('d-none');
    }
  }
}
