import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['stateWebcastSection']

  openWebcastModal(event) {
    var webcastController = this.application.getControllerForElementAndIdentifier(
      this.stateWebcastSectionTarget,
      'vod--state-webcasts'
    )
    webcastController.openWebcastModal(event)
  }
}
