import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['content', 'loadMore']

  connect() {
    this.data.set('filterParams', window.location.search.substring(1))
    if (!this.data.has('show-more')) this.hideButton()
  }

  loadNextPage(event) {
    event.preventDefault()
    this.disableButton()
    this.requestNextPage()
  }

  updateParams(event) {
    this.data.set('filterParams', event.detail.filterParams)
    this.toggleButton(event.detail.loadMore)

    if (event.detail.nextUrl !== undefined) {
      this.data.set('nextUrl', event.detail.nextUrl)
    }
    this.ratioDisplay()
  }

  disableButton() {
    this.loadMoreTarget.disabled = true
    this.loadMoreTarget.textContent = this.data.get('loading-text')
    this.loadMoreTarget.style = 'pointer-events: none; opacity: .5;'
  }

  enableButton() {
    this.loadMoreTarget.disabled = false
    this.loadMoreTarget.textContent = this.data.get('see-more-text')
    this.loadMoreTarget.removeAttribute('style')
  }

  hideButton() {
    this.loadMoreTarget.style.display = 'none'
  }

  toggleButton(loadMore) {
    if (loadMore) {
      this.enableButton()
    }
    else {
      this.hideButton()
    }
  }

  requestNextPage() {
    fetch(this.data.get('nextUrl') + '&' + this.data.get('filterParams'))
      .then(response => response.json())
      .then(data => {
        this.toggleButton(data.load_more)
        this.data.set('nextUrl', data.next_page_url)
        this.contentTarget.insertAdjacentHTML('beforeend', data.result)
        this.ratioDisplay()
      }).catch(error => {
        console.error(error)
      })
  }

  ratioDisplay() {
    const element = document.querySelector('.ratio-display')
    if (element) {
      element.querySelectorAll('.card-img-top').forEach(image => {
        image.style.height = `${image.offsetWidth * 0.5625}px`
      })
    }
  }
}
