import { Controller } from "stimulus";
import Trix from "trix";
const lang = Trix.config.lang;

export default class extends Controller {
  connect() {
    addEventListener("trix-initialize", () => {
      this.initTrixTableButton();
    });
  }

  initTrixTableButton() {
    Trix.config.lang.table = I18n.t("action_text.toolbars.table");
    const tableButtonHTML = `
      <button
        type="button"
        class="trix-custom-table-button trix-button trix-button--icon trix-button--icon-table"
        data-action="rich-text-table#attachTable"
        title="${I18n.t("action_text.toolbars.table")}">
        ${lang.table}
      </button>`;
    const fileToolsElement = this.element.querySelector(
      "[data-trix-button-group=file-tools]"
    );

    if (fileToolsElement && !this.element.querySelector(".trix-custom-table-button")) {
      fileToolsElement.insertAdjacentHTML("beforeend", tableButtonHTML);
    }
  }

  attachTable(event) {
    $.ajax({
      url: `/tables`,
      type: "post",
      success: this.insertTable.bind(this),
    });
  }

  insertTable(tableAttachment) {
    this.attachment = new Trix.Attachment(tableAttachment);
    this.element
      .querySelector("trix-editor")
      .editor.insertAttachment(this.attachment);
    this.element.focus();
  }
}
