import { Controller } from "stimulus";
import Trix from "trix";

const MAX_PARENT_SEARCH_DEPTH = 5;

export default class extends Controller {
  addRow(event) {
    $.ajax({
      url: `/tables/${this.getID()}`,
      data: "method=addRow",
      type: "patch",
      success: this.attachTable.bind(this),
    });
  }

  addColumn(event) {
    $.ajax({
      url: `/tables/${this.getID()}`,
      data: "method=addColumn",
      type: "patch",
      success: this.attachTable.bind(this),
    });
  }

  removeLastRow(event) {
    $.ajax({
      url: `/tables/${this.getID()}`,
      data: "method=removeLastRow",
      type: "patch",
      success: this.attachTable.bind(this),
    });
  }

  removeLastColumn(event) {
    $.ajax({
      url: `/tables/${this.getID()}`,
      data: "method=removeLastColumn",
      type: "patch",
      success: this.attachTable.bind(this),
    });
  }

  updateCell(event) {
    console.log("EVENT", event);
    $.ajax({
      url: `/tables/${this.getID()}`,
      data: `method=updateCell&cell=${encodeURIComponent(
        event.target.dataset.key
      )}&value=${encodeURIComponent(event.target.value)}`,
      type: "patch",
    });
  }

  getID() {
    return this.data.get("id");
  }

  attachTable(tableAttachment) {
    let attachment = new Trix.Attachment(tableAttachment);
    var parent = this.element.parentNode;
    if (parent) {
      var editorNode = null;
      for (let i = 0; i < MAX_PARENT_SEARCH_DEPTH; i++) {
        editorNode = parent.querySelector("trix-editor");
        if (editorNode !== null) {
          i = MAX_PARENT_SEARCH_DEPTH;
        } else {
          parent = parent.parentNode;
        }
      }
      editorNode.editor.insertAttachment(attachment);
    }
  }
}
